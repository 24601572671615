import * as React from "react";

export default function aboutWorkContact() {
    return (
        <div>
            <div className="max-width">

                <div className=" mb-0">
                    <div className="float-right">
                        <a href="/">
                            <p className="text-xs mt-1 on-hover-40 text-white label-english"></p>
                        </a>
                    </div>
                </div>

                <div className="vertical-center">
                    <h1 className="font-fira-code">
                        <div className="hide-text-block">
                            <a href="/fr/about">
                                <div className="animate text-white on-hover-40 hide-web label-hello-fr"></div>
                                <div className="animate text-white on-hover-40 hide-mobile toggle-mobile-second hidden ">
                                    À Propos
                                </div>
                            </a>
                        </div>

                        <div className="hide-text-block">
                            <a href="/fr/work">
                                <div className="animate text-purple on-hover-40 delay04 hide-web label-iam-fr"></div>
                                <div className="animate text-purple on-hover-40 delay04 hide-mobile toggle-mobile-second hidden ">
                                    Projets
                                </div>
                            </a>
                        </div>
                        <div className="hide-text-block">
                            <a href="/fr/contact">
                                <div className="animate text-purple on-hover-40 delay08 hide-web label-Lincey-fr"></div>
                                <div className="animate text-purple on-hover-40 delay08 hide-mobile toggle-mobile-second hidden ">
                                    Contact
                                </div>
                            </a>
                        </div>
                    </h1>
                </div>
            </div>
        </div>
    );
}
